.BoardCard_link__BCgVi {
  whitespace: "nowrap";
  overflow: "hidden";
  textoverflow: "ellipsis";
  fontsize: var(--font-size-sm);
}
  @media (max-width: 300px) {.BoardCard_link__BCgVi {
    fontsize: var(--font-size-xs)
}
  }

.Features_featureHero__qNqME {
  background-color: var(--mantine-color-default);
}
  [data-mantine-color-scheme='dark'] .Features_featureHero__qNqME {
  background-color: var(--mantine-color-default);
}
.Features_wrapper__6poqP {
  /* paddingTop: `calc(${theme.spacing.xl} * 4)`;
    paddingBottom: `calc(${theme.spacing.xl} * 4)`; */
}

.Features_title__slddC {
  /* fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 28,
      textAlign: "left",
    }, */
}

.Features_description__Hw1at {
  /* textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    }, */
}

.HomeHero_hero__c_tvW {
  position: relative;
}

.HomeHero_container__2Iuca {
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: calc(var(--mantine-spacing-xl) * 6);
  zindex: 1;
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 36em) {

.HomeHero_container__2Iuca {
    height: 500px;
    padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    padding-left: 20px;
    padding-right: 20px
}
  }

.HomeHero_title__f9v5G {
  color: theme.white;
  font-size: 60px;
  line-height: 1.1;
}

@media (max-width: 48em) {

.HomeHero_title__f9v5G {
    font-size: 40px;
    line-height: 1.2
}
  }

@media (max-width: 36em) {

.HomeHero_title__f9v5G {
    font-size: 28px;
    line-height: 1.3
}
  }

.HomeHero_description__soSl_ {
  color: var(--mantine-color-white);
  max-width: 600px;
}

@media (max-width: 48em) {

.HomeHero_description__soSl_ {
    max-width: 100%;
    font-size: var(--mantine-font-size-sm)
}
  }
.HomeHero_control__lqbYM {
  width: 200px;
}
@media (max-width: 36em) {
.HomeHero_control__lqbYM {
    width: 100%
}
  }

