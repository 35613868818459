.hero {
  position: relative;
}

.container {
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: calc(var(--mantine-spacing-xl) * 6);
  zindex: 1;
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: $mantine-breakpoint-xs) {
    height: 500px;
    padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  color: theme.white;
  font-size: 60px;
  line-height: 1.1;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 40px;
    line-height: 1.2;
  }

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: 28px;
    line-height: 1.3;
  }
}

.description {
  color: var(--mantine-color-white);
  max-width: 600px;

  @media (max-width: $mantine-breakpoint-sm) {
    max-width: 100%;
    font-size: var(--mantine-font-size-sm);
  }
}
.control {
  width: 200px;
  @media (max-width: $mantine-breakpoint-xs) {
    width: 100%;
  }
}
