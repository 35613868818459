.featureHero {
  background-color: light-dark(
    var(--mantine-color-default),
    var(--mantine-color-default)
  );
}
.wrapper {
  /* paddingTop: `calc(${theme.spacing.xl} * 4)`;
    paddingBottom: `calc(${theme.spacing.xl} * 4)`; */
}

.title {
  /* fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 28,
      textAlign: "left",
    }, */
}

.description {
  /* textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    }, */
}
